<template>
  <div>

    <!-- Main Row -->
    <div class="row">

      <!-- Form -->
      <div class="col-md-12">
        <Card :title="'Detail Gudang'">
          <template v-slot:body>
            <div class="row align-items-center">

              <!-- Table Data -->
              <div
                class="col"
              >
                <table class="table mb-3">
                  <tr>
                    <td style="width: 25%"><strong>Nama</strong></td>
                    <td>{{ data.name }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%"><strong>Gedung</strong></td>
                    <td>{{ data.address }}</td>
                  </tr>
                  <tr>
                    <td><strong>Deskripsi</strong></td>
                    <td>{{ data.description }}</td>
                  </tr>
                </table>

                <button
                  @click="$router.push({path: '/masters/warehouses/edit/' + data.id})"
                  v-if="btn"
                  class="btn mx-1 btn-success"
                ><i class="far fa-fw fa-edit"></i> Edit</button>
                <button
                  @click="deleteData"
                  class="btn mx-1 btn-danger"
                  v-if="btn"
                ><i class="far fa-fw fa-trash-alt"></i> Hapus</button>
              </div>

               <!-- Profile Image -->
              <div class="col-md-5">
                  <div class="row">
                    <div class="row-md-12">
                      <h5>Daftar Rak</h5>
                    </div>
                    <div class="col-md-12">
                      
                      <b-table
                        striped
                        hover
                        responsive
                        class="mt-3"
                        :items="dataRack"
                        :fields="fields"
                      >
                        <template #table-colgroup="scope">
                          <col
                            v-for="field in scope.fields"
                            :key="field.key"
                            :style="{ width: field.key === 'actions' ? '20%' : '' }"
                          />
                        </template>
                      </b-table>
                      <b-pagination
                        v-if="dataRack.length != 0"
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        @page-click="pageOnClick"
                        class="mt-4"
                      ></b-pagination>
                    </div>
                  </div>
              </div>

            </div>
          </template>
        </Card>
      </div>

    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  data() {
    return {
      // Data
      data: {
        name: "",
        description: ""
      },
      dataRack:[],
      // Other
      imgLoaded: false,
      // user access
      btn: false,
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
      ],

      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
    }
  },

  components: {
    Card,
  },

  methods: {

    onImageLoad() {
      this.imgLoaded = true
    },

    pageOnClick(evt, page) {
      evt.preventDefault()
      this.currentPage = page
      this.getRack()
    },

    async deleteData() {
      let result = await module.delete('warehouses/' + this.$route.params.id)
      // If Deleted
      if (result) {
        // Redirect To List
        this.$router.push('/masters/warehouses/list')
      }
    },

    async get() {
      this.data = await module.get('warehouses/' + this.$route.params.id)
      // If Data Not Found
      if (this.data == null) {
        // Redirect To List
        this.$router.push('/masters/warehouses/list')
      }
    },
    
    async getRack() {
      this.dataRack = await module.get('warehouses-rack/' + this.$route.params.id)
    },

    setActiveButton(){
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)

      let a
      for(a = 0; a < access_right.length; a++){
        if(access_right[a] == "1802"){
          this.btn = true
        }
      }
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Gudang", route: "" },
      { title: "Daftar Gudang", route: "/masters/warehouses/list" },
      { title: "Detail" },
    ])
    // Get Data
    this.get()
    this.getRack()
    this.setActiveButton()
  },
}

</script>